function getCookie(cookieKey) {
	let value = "";
	const key = cookieKey ? cookieKey : "gtm";
	const cookies = document.cookie.split(";");
	for (const keyValuePair of cookies) {
		const [k, v] = keyValuePair.split("=");
		if (key === k.trim()) {
			value = v.trim();
		}
	}

	return value;
}

window.getCookie = getCookie;

/* Google Tag Manager  */

(function (w, d, s, l, i) {
	w[l] = w[l] || [];
	w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
	var f = d.getElementsByTagName(s)[0],
		j = d.createElement(s),
		dl = l !== "dataLayer" ? "&l=" + l : "";
	j.async = true;
	j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
	f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", window.getCookie());
/* End Google Tag Manager */

/* Add WOGAA tracking url*/
(function setWogaaTracking(d, s, url) {
	if (url) {
		const script = d.createElement(s);
		script.type = "text/javascript";
		script.src = url;
		d.getElementsByTagName("head")[0].appendChild(script);
	}
})(document, "script", window.getCookie("wogaa"));
/* End of Add WOGAA tracking url*/
